<template>
  <div class="autnft-roadmap" id="roadmap">
        <div class="autnft-typography__title--smaller text-center font-bold mt-10">Aut<span class="autnft--lightBlue">nft</span>.com Roadmap</div>
        <div class="autnft-roadmap--content container xl-auto ">

            <div class="autnft-roadmap--content__firstRow">
                <div class="autnft-roadmap--content__lineContainer">
                    <img class="absolute inset-x-0 -bottom-0.5 mx-auto w-36 z-0" src="/assets/svg/line_small.svg" alt="">
                </div>
                <div class="autnft-roadmap--feature__alt">
              <div class="autnft-roadmap--featureContent--alt ">
                <div class="autnft-roadmap--featureContent__title">AutNFT Wallet integration</div>
                <div class="autnft-roadmap--featureContent__description">
                  We will integrate MetaMask into our website to facilitate the registration of your NFT certificate.
                </div>
              </div>
                </div>
              <div class="autnft-roadmap--content__lineContainer">
                    <img class="absolute inset-x-0 -bottom-4 mx-auto w-36 z-0 transform" src="/assets/svg/line_small_right.svg" alt="">
                </div>
                <div class="autnft-roadmap--feature__alt">
                <div class="autnft-roadmap--featureContent--alt ">
                    <div class="autnft-roadmap--featureContent__title">AutNFT Marketplace</div>
                    <div class="autnft-roadmap--featureContent__description"> 
                    Buy and sell your AutNFT Card with other users directly on our Marketplace!
                    </div>
                </div>
                </div>

                <div class="autnft-roadmap--content__lineContainer ">
                    <img class="absolute inset-x-0 -bottom-4 mx-auto w-36 z-0 transform rotate-180" src="/assets/svg/line_large.svg" alt="">
                </div>

                 <div class="autnft-roadmap--feature__alt">
              <div class="autnft-roadmap--featureContent--alt">
                <div class="autnft-roadmap--featureContent__title">New AutNFT Certificate Types</div>
                <div class="autnft-roadmap--featureContent__description">
                  Not just domains: We will add the possibility to certificate new types of content!
                </div>
              </div>
            </div>

            </div>
            <div class="autnft-roadmap--content__iconRow">
                <div class="autnft-roadmap--content__iconContainer">
                    <img class="autnft-roadmap--icon" src="/assets/icons/hexagon-on.svg" alt="">     
                </div>
                <div class="autnft-roadmap--content__iconContainer">
                    <img class="autnft-roadmap--icon autnft-roadmap--iconOFF" src="/assets/icons/hexagon-off.svg" alt="">
                </div>
                <div class="autnft-roadmap--content__iconContainer">
                    <img class="autnft-roadmap--icon autnft-roadmap--iconOFF" src="/assets/icons/hexagon-off.svg" alt="">
                </div>
                <div class="autnft-roadmap--content__iconContainer">
                    <img class="autnft-roadmap--icon autnft-roadmap--iconOFF" src="/assets/icons/hexagon-off.svg" alt="">
                </div>
                <div class="autnft-roadmap--content__iconContainer">
                    <img class="autnft-roadmap--icon autnft-roadmap--iconOFF" src="/assets/icons/hexagon-off.svg" alt="">
                </div>
                <div class="autnft-roadmap--content__iconContainer">
                    <img class="autnft-roadmap--icon autnft-roadmap--iconOFF" src="/assets/icons/hexagon-off.svg" alt="">
                </div>
            </div>

            <div class="autnft-roadmap--content__secondRow">
            <div class="autnft-roadmap--feature">
              <div class="autnft-roadmap--featureTriangle"></div>
              <div class="autnft-roadmap--featureContent">
                <div class="autnft-roadmap--featureContent__title">AutNFT Project Started</div>
                <div class="autnft-roadmap--featureContent__description">
                  Let's reach 500 NFT Certification registered to unlock the dates and the development of the future features!
                </div>
              </div>
            </div>

            <div class="autnft-roadmap--content__lineContainer">
                <img class="absolute inset-x-0 -top-4 mx-auto w-36 z-0 transform" src="/assets/svg/line_large.svg" alt="">
            </div>

            <div class="autnft-roadmap--feature">
              <div class="autnft-roadmap--featureContent--alt ">
                <div class="autnft-roadmap--featureContent__title"> Start of the AutNFT Project</div>
                <div class="autnft-roadmap--featureContent__description">
                  The first objective is to reach 500 single registered certificates!
                </div>
              </div>
            </div>

            <div class="autnft-roadmap--content__lineContainer">
                <img class="absolute inset-x-0 -top-4 mx-auto w-36 z-0 transform" src="/assets/svg/line_small_up.svg" alt="">
            </div>

            <div class="autnft-roadmap--feature">
              <div class="autnft-roadmap--featureContent--alt ">
                <div class="autnft-roadmap--featureContent__title">AutNFT Affiliate System</div>
                <div class="autnft-roadmap--featureContent__description">
                  Share our mission with other users and earn a commsion each time one of them will register a new certificate!
                </div>
              </div>
            </div>

            <div class="autnft-roadmap--content__lineContainer">
                <img class="absolute inset-x-0 -top-4 mx-auto w-36 z-0 transform" src="/assets/svg/line_small_up.svg" alt="">
            </div>
            </div>
            
          
          <div class="autnft-roadmap--line"></div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'autnft-homeroadmap'
}
</script>

<style scoped>
@import url('../../assets/css/buttons.css');
@import url('../../assets/css/colors.css');

 .autnft-roadmap {
    background-color: white;
    width: 100%;
    text-align: center;
    height: 100%;
    color: #06142d;
    margin-top: -100px;
    position: absolute;
    z-index: 11;
  }
  
  .autnft-roadmap--content {
    display: grid;
    grid-template-rows: 150px 30px 150px;
  }

  .autnft-roadmap--lineSmall {
    width: 140px;
  }

  .autnft-roadmap--lineBig__alt {
    grid-row: 3;
    width: 140px;
    margin: 0 auto;
  }

  .autnft-roadmap--content__iconContainer{
    grid-row: 2;
    z-index: 10;
    width: 30px;
    margin: 0 auto;
  }

  .autnft-roadmap--content__iconContainer:first-of-type {
      grid-column: 1;
  }

  .autnft-roadmap--content__lineContainer {
      position: relative;
  }

  .autnft-roadmap--iconOFF {
    width: 25px;
    margin: 2.5px auto 0;
  }

  .autnft-roadmap--line {
    grid-column: 1 / span 6;
    grid-row: 2;  
    width: 100%;
    height: 2px;
    background: -webkit-linear-gradient(45deg,#fff 4%, #87d4ff 11%, #be7fff 94%, #fff 96%);
    margin-top: 15px;
    z-index: 1;
  }

  .autnft-roadmap--content__iconRow {
    grid-column: 1 / span 6;
    grid-row: 2;  
    display: grid;
    grid-template-columns: repeat(6,1fr);
    padding: 0 50px;
  }
  
  .autnft-roadmap--content__firstRow {
    grid-column: 1 / span 6;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    padding: 0 50px;
  }

  .autnft-roadmap--content__secondRow {
    grid-column: 1 / span 6;
    grid-row: 3;
    display: grid;
    grid-template-columns: repeat(6,1fr);
    padding: 0 50px;
  }

  .autnft-roadmap--feature {
    width: 200px;
    margin: 0 auto;
  }

  .autnft-roadmap--feature__alt {
    width: 200px;
    margin: 0 auto;
  }

  .autnft-roadmap--featureTriangle{
    content: ' ';
    width: 0; 
    height: 0; 
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #06142D;
    margin: 0 auto;
  }

  .autnft-roadmap--featureContent {
    background-color: #06142D;
    padding: 10px;
    border-radius: 15px;
    color: white;
  }

  .autnft-roadmap--featureContent--alt {
    padding: 10px;
    color: #06142d;
  }

.autnft-roadmap--featureContent__title {
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 7px;
}

.autnft-roadmap--featureContent__description {
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
}


</style>
