<template>
  <div class="autnft-typography__title--smaller text-center font-semibold mt-10">Aut<span class="autnft--lightBlue">nft</span>.com Features</div>
  <div class="autnft-homeFeatures">
       <div class="autnft-home--whiteEllipse"></div>
       <div class="autnft-homeFeatures--containers">
        <HomeFeature 
        iconPath="../../assets/icons/shield.svg" 
        iconAlt="Shield Icon"
        title="THE FIRST WORLDWIDE NFT CARD CERTIFICATION"
        description="We are the first and unique platform to register and authenticate your domain, including the concepts and idea associated with this, into the ETH Blockchain Network using the NFT Technology."
        >
       </HomeFeature>
       <HomeFeature 
        iconPath="/assets/icons/webpage.svg" 
        iconAlt="WebPage Icon"
        title="PROOF OVER TIME OF YOUR IDEAS"
        description="When your domain is registered as NFT, the most important pages chosen by you will be saved into the AutNFT DB, provide the existence of the concept at the registration date forever."
        >
       </HomeFeature>
       <HomeFeature 
        iconPath="../assets/icons/card-folder.svg" 
        iconAlt="Card Folder Icon"
        title="COLLECT AND EXCHANGE NFT CARDS"
        description="The NFT of your domain will be in your own wallet, including the connected information, a QR Card, and an animated Card. Keep or exchange your NFT freely with other users.  "
        >
       </HomeFeature>
       </div>
    </div>
</template>

<script>
import HomeFeature from '@/components/Home/HomeFeature.vue'
export default {
  name: 'homefeatures',
  components: {
      HomeFeature
  }
}
</script>

<style scoped>
@import url('../../assets/css/buttons.css');
@import url('../../assets/css/colors.css');

.autnft-home--whiteEllipse {
    border-radius: 100%;
    background-color: white;
    color: black;
    width: 100%;
    height: 200px;
    z-index: 10;
  }

  .autnft-homeFeatures{
    margin-top: 70px;
    width: 100%;
    min-height: 120px;
    height: 100%;
    position: relative;
    display: grid;
  }

  .autnft-homeFeatures--containers {
    display: grid;
    grid-row: 1;
    grid-template-columns: repeat(3,300px);
    column-gap: 100px;
    justify-content: center;
  }

</style>
