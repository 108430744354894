<template>
  <div class="autnft-homeFeature--container">
        <div class="autnft-homeFeatureShadow"></div>
        <div class="autnft-homeFeatureContent">
        <div class="autnft-homeFeatureContent--icon">
            <img v-bind:src="iconPath" :alt=iconAlt>
        </div>
        <div class="autnft-homeFeatureContent--title">
            {{title}}
        </div>
        <div class="autnft-homeFeatureContent--text">
            {{description}}
        </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        iconPath: String,
        iconAlt: String,
        title: String,
        description: String
    },
    name: 'homefeature'
}
</script>

<style scoped>
@import url('../../assets/css/buttons.css');
@import url('../../assets/css/colors.css');

.autnft-homeFeature--container {
    position: relative;
    margin-top: -50px;
    width: 100%;
    height: 100%;
    grid-row: 1;
    display: flex;
    justify-content: center;
  
  }

  .autnft-homeFeatureShadow{
    position:absolute;
    box-shadow: 0px 0px 43px 7px rgba(35, 84, 166, 1);
    background-color: transparent;
    border-radius: 10px;
    min-height: 100px;
    width: 310px;
    z-index: 1;
  }

  .autnft-homeFeatureContent{
    position:absolute;
    background-color: #06142d;
    border-radius: 10px;
    min-height: 160px;
    width: 310px;
    z-index: 20;
    padding: 10px;
  }


  .autnft-homeFeatureContent--icon {
    float: left;
    margin: 0 10px 0 0;
    width: 70px;
  }

  .autnft-homeFeatureContent--title{
    font-weight: 800;
    font-size: 16px;
    line-height: 1.25rem;
    margin-bottom: 5px;
  }

  .autnft-homeFeatureContent--text{
    font-weight: 200;
    font-style: italic;
  }


</style>
