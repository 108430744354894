<template>
    <Menu></Menu>
  <router-view/>
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/shared/Menu.vue'

export default {
  name: 'App',
  components: {
    Menu
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import url('./assets/css/formFields.css');
@import url('./assets/css/typography.css');
@import url('./assets/css/buttons.css');
@import url('./assets/css/gradients.css');
@font-face {
    font-family: LemonMilk;
    src: url("./assets/fonts/LEMONMILK-Bold.otf") format("opentype");
}

body{
  margin: 0;
  padding: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(ellipse 50% 80%, #471FAD, #080842);
  min-height: 100vh;
  font-family: 'Nunito', sans-serif;
  font-size: 13px;
}


</style>
