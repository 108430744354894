<template>
  <div class="autnft-home  text-white">
    <div class="autnft-project container xl-auto">
      <div class="autnft-project--description">
        <div class="autnft-typography__title font-bold mb-4 leading-8">
          AutNFT is the first <span class="autnft-project--description__lightBlue">NFT</span>  Platform <br> to certificate your domain and ideas.
        </div>
        <div class="autnft-typography__paragraph autnft-typography__italic autnft-typography__extraLight mb-3">
          After that our brand and our website have been cloned by a third party, taking <br>
          advantage of our work of 4 years, we understood that the blockchain network <br>
          and the NFT can represent the solution: <br>
          Thanks to AutNFT you will include your realized ideas, concepts, and  <br>
          creations of your websites into the blockchain network.
        </div>
        <div class="autnft-typography__paragraph autnft-typography__italic autnft-typography__extraLight mb-3">
          Your domain will be certified by an NFT owned by you: <br> Keep or exchange it with other users. <br>  
          Your website will be in the AutNFT Database forever, <br>   publicly available and certifying your creation.
        </div>
        <div class="autnft-typography__paragraph autnft-typography__italic autnft-typography__extraLight mb-3">
          Our mission is to bring intellectual property authentication into the blockchain, <br>
          not just for the domains but for everything that human beings can create, <br>
          and we strongly believe that thanks to the trust of our users <br> we will reach our common goal.
        </div>
        <div class="mt-5">
        <a href="" class="autnft--button autnft-typography__subtitle--smaller font-semibold autnft--lightBlueGradientBG">
           Auth your domain with NFT
         </a>
        </div>
         

        <div class="autnft-project--description__qrcard">
          
        </div>
      </div>
      <div class="autnft-project--search">
        <img class="autnft-project--search__bg" src="/assets/world_autnft.svg" alt="" srcset="">
        <div class="autnft-project--search__title">
          AUTNFT SEARCH
        </div>
        <div class="autnft-project--search__subtitle">
          Access the AUTNFT Database
        </div>
        <div class="autnft-project--search__text">
          Search between all the AutNFT Certificates of marks, website and owners:
        </div>
        <div class="autnft-project--searchBar">
          <input type="text" placeholder="Ricerca tutti i maschioni coi quali Luca ha trumbatos!" class="autnft-project--searchInputField" >
          <button class="autnft--button autnft-typography__subtitle--smaller font-semibold autnft--lightBlueGradientBG">Search</button>
        </div>

      </div>
    </div>
    <HomeFeatures></HomeFeatures>
    <HomeRoadmap></HomeRoadmap>
   
      
    </div>
</template>

<script>
import HomeFeatures from '@/components/Home/HomeFeatures.vue'
import HomeRoadmap from '@/components/Home/HomeRoadmap.vue'

export default {
  name: 'Home',
  components: {
    HomeFeatures,
    HomeRoadmap
  }
}
</script>


<style scoped>
  .autnft-project {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1266px;
    margin: 0 auto;
  }

  .autnft-project--description__title {
    font-size: 28px;
    font-weight: bold;
    line-height: 2.rem;
    margin-bottom: 15px;
  }

  .autnft-project--description__paragraph {
    font-weight: 200;
    font-style: italic;
    font-size: 14px;
    margin-bottom: 15px;
  }

  .autnft-project--search {
    text-align: right;
    display: grid;
    grid-template-rows: 50px 30px 30px;
    z-index: 10;

  }

  .autnft-project--search__bg {
    position: absolute;
    width: 800px;
    top: 200px;
    right: -10;
    grid-row: 1 / span 4;
    grid-column: 1;
    z-index: 1;
  }

  .autnft-project--searchBar {
    grid-row: 4;
    grid-column: 1;
    z-index: 10;
  }

  .autnft-project--searchInputField {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 25px;
    width: 300px;
    height: 35px;
    margin: 0 10px;
  }

  .autnft-project--search__title {
    grid-row: 1;
    grid-column: 1;
    font-size: 36px;
    font-family: 'LemonMilk', 'Nunito', sans-serif;
    font-weight: bold;
    background: -webkit-linear-gradient(45deg, #87d4ff 50%, #be7fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .autnft-project--search__subtitle {
    grid-row: 2;
    grid-column: 1;
    font-size: 18px;
    font-weight: 800;
  }

  .autnft-project--search__text {
    grid-row: 3;
    grid-column: 1;
    font-size: 14px;
    font-weight: 800;
  }

  .autnft-project--description__lightBlue {
    color: #60caf0;
  }

  .autnft-project--searchInputField {
    color: black;
    padding: 5px;
    text-overflow: ellipsis;
  }
</style>