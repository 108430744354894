<template>
  <div class="autnft-menu container xl-auto items-center">
    <router-link to="/">
        <div class="autnft-menu__logo items-center">
            <img class="autnft-menu__logoImage" src="/assets/svg/logo.svg" alt="AutNFT Logo">
            <div class="autnft-menu__logoName">AutNFT.com</div>
        </div>
    </router-link> 
    <div class="autnft-menu__items ">
        <router-link to="/#project">
            <span class="autnft-menu__item">
                Project
            </span>
        </router-link> 
        <router-link to="/#features">
            <span class="autnft-menu__item">
                Features
            </span>
        </router-link> 
        <router-link to="/#roadmap">
            <span class="autnft-menu__item">
                Roadmap
            </span>
        </router-link> 
        <router-link to="/getnft">
            <span class="autnft--button autnft--greenGradientBG">
                Get your NFT certificate
            </span>
        </router-link> 
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutNFT-Menu'
}
</script>

<style scoped>
@import url('../../assets/css/buttons.css');
@import url('../../assets/css/gradients.css');

.autnft-menu {
    display: flex;
    justify-content: center;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.autnft-menu a {
  font-weight: bold;
  color: #ffffff;
  text-decoration: none;
}

.autnft-menu__items{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    max-width: 600px;
}

.autnft-menu__logo {
    display: flex;
}

.autnft-menu__logoImage {
    width: 40px;
}

.autnft-menu__logoName {
    margin-left: 15px;
}

</style>
